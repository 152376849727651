// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/role/config.ts"
);
import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

export const config = {
    path: '/dashboard/settings/roles'
}